import { NotificationType } from '~/types/types';

export const AIRBRAKE_PROJECT_ID = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID;
export const AIRBRAKE_PROJECT_KEY = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY;
export const AIRBRAKE_ENVIRONMENT = process.env.NEXT_PUBLIC_AIRBRAKE_ENVIRONMENT;
export const ROOT_REDIRECT_URL = process.env.NEXT_PUBLIC_ROOT_REDIRECT_URL;
export const ASSETS_URL = process.env.NEXT_PUBLIC_ASSETS_URL;
export const CARRIER_ASSETS_URL = 'https://static.stiteknabalik.cz';

export const RATING_COMMENT_MAX_LENGTH = 300;

/** Notification (toast) duration in milliseconds, */
export const NOTIFICATION_DURATION: Record<NotificationType, number> = {
  success: 4000,
  warning: 6000,
  error: 10000,
  info: 4000,
};
